import { TABLE_NAMES, ISSUE_DOCUMENTS_TYPES, ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES } from "@/constants"
import { getFootersForTable } from "@/utils/table"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "IssueDocuments",
  components: {
    ThemisDecision
  },
  props: {
    pDocumentsOfReport          : Array,
    pDocumentsOfIssue           : Array,
    pUsersMap                   : Object,
    pIssueDocumentRemovePolicies: Array,
    pIssueAddDocumentsPolicy    : Object,
    pIsAddingIssueDocument      : Boolean,
    pIsRemovingIssueDocument    : Boolean,
    pIsIssueDocumentRemoved     : Boolean,
    pIsReportAnonymised         : Boolean
  },
  data: () => ({
    documentToBeRemoved     : undefined,
    showRemoveDocumentDialog: false
  }),
  computed: {
    canAddDocuments() {
      return this.pIssueAddDocumentsPolicy?.set?.add !== undefined
    },
    allowedIssueDocumentsTypes() {
      return Object.values(ISSUE_DOCUMENTS_TYPES)
    },
    documentsForDisplaying() {
      const documentsForDisplaying = new Array()
      if (this.pDocumentsOfIssue) {
        for (const document of this.pDocumentsOfIssue) {
          let createdBy
          let disabledButNotDeleted
          const isDocumentRemoved = !document.name
          const user              = this.pUsersMap[document.creatorId]
          if(user) {
            createdBy             = user.name,
            disabledButNotDeleted = user.disabledButNotDeleted
          }
          documentsForDisplaying.push({
            id              : document.id,
            name            : isDocumentRemoved ? this.$tc("783") : document.name,
            type            : this.$t("656"),
            createdAt       : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument: false,
            disabledButNotDeleted,
            createdBy,
            isDocumentRemoved
          })
        }
      }
      if (this.pDocumentsOfReport) {
        for (const document of this.pDocumentsOfReport) {
          const isDocumentRemoved = !document.value
          documentsForDisplaying.push({
            id              : document.id,
            name            : isDocumentRemoved ? this.$tc("783") : document.value.name,
            type            : this.$t("657"),
            createdBy       : this.$t("1000"),
            createdAt       : this.$moment(document.createdAt).format("DD MMMM YYYY HH:mm (UTCZ)"),
            isReportDocument: true,
            isDocumentRemoved
          })
        }
      }
      return documentsForDisplaying
    },
    headersForTable() {
      return this.$TABLES.ISSUE_DOCUMENTS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ISSUE_DOCUMENTS, this.$t.bind(this))
    }
  },
  methods: {
    downloadAll() {
      for (const document of this.pDocumentsOfIssue) {
        this.$emit("downloadIssueDocument", document)
      }
      if (!this.pIsReportAnonymised) {
        for (const document of this.pDocumentsOfReport) {
          this.$emit("downloadReportDocument", document)
        }
      }
    },
    download(document) {
      if (document.isReportDocument) {
        this.$emit("downloadReportDocument", this.pDocumentsOfReport.find(documentFromReporter => documentFromReporter.id === document.id))
      } else {
        this.$emit("downloadIssueDocument", this.pDocumentsOfIssue.find(documentFromUser => documentFromUser.id === document.id))
      }
    },
    openFileExplorerToSelectDocuments() {
      this.$refs.input_add_documents.click()
    },
    onDocumentSelection(event) {
      for (const file of event.target.files) {
        if (file.size < ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES) {
          this.$emit("addDocument", file)
        }
      }
    },
    openRemoveDocumentDecision(documentToBeRemoved) {
      this.documentToBeRemoved      = documentToBeRemoved
      this.showRemoveDocumentDialog = true
    },
    closeRemoveDocumentDecison() {
      this.showRemoveDocumentDialog = false
      this.documentToBeRemoved      = undefined
    },
    canRemoveDocument(documentId) {
      const documentRemovePolicy = this.pIssueDocumentRemovePolicies.find(issueDocumentRemovePolicy =>
        issueDocumentRemovePolicy.id === documentId
      )
      return documentRemovePolicy?.set?.remove !== undefined
    },
    isDeleteButtonDisplayed(isReportDocument, itemId, isItemDocumentRemoved) {
      if (isReportDocument) {
        return false
      } else {
        return this.canRemoveDocument(itemId) && !isItemDocumentRemoved
      }
    }
  },
  watch: {
    pIsIssueDocumentRemoved: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.$emit("documentRemoved", this.documentToBeRemoved)
          this.showRemoveDocumentDialog = false
          this.documentToBeRemoved      = undefined
        }
      }
    },
    pIsRemovingIssueDocument: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_DOCUMENT.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}