var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"data-cy":"537"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"538"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',{staticClass:"pa-3",attrs:{"data-cy":"539"}},[_c('v-card-title',{attrs:{"data-cy":"540"}},[_c('span',{ref:"dynamic_screen_title",staticClass:"primary--text",attrs:{"data-cy":"541"}},[_c('span',{ref:"static_screen_title",staticClass:"primary--text",attrs:{"data-cy":"542"}},[_vm._v(_vm._s(_vm.pStaticScreenTitle))]),_vm._v(_vm._s(_vm.pDynamicScreenTitle))])]),_c('v-list-item',{attrs:{"data-cy":"543"}},[_c('v-list-item-content',{attrs:{"data-cy":"544"}},[_c('span',{ref:"screen_description",staticClass:"mb-6",attrs:{"data-cy":"3510"}},[_vm._v(_vm._s(_vm.pScreenDescription))]),_vm._l((_vm.localScreenItems),function(localScreenItem,index){return [(localScreenItem.type === _vm.$CONSTANTS.WIDGET.AUTOCOMPLETE)?_c('themis-input',{ref:("themis_input_" + index),refInFor:true,attrs:{"data-cy":"1405","p-label-class":"secondary--text","p-label":_vm.getScreenItemLabel(localScreenItem),"p-rules":_vm.getScreenItemRules(localScreenItem),"p-name":_vm.getScreenItemName(localScreenItem)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:("screen_item_autocomplete_" + index),refInFor:true,attrs:{"data-cy":"1406","item-text":_vm.getItemText(localScreenItem),"item-value":_vm.getItemValue(localScreenItem),"color":"secondary","items":localScreenItem.items,"placeholder":_vm.getScreenItemPlaceholder(localScreenItem),"error-messages":errors,"hint":_vm.getScreenItemHint(localScreenItem),"outlined":"","persistent-hint":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(localScreenItem.value),callback:function ($$v) {_vm.$set(localScreenItem, "value", $$v)},expression:"localScreenItem.value"}})]}}],null,true)}):_vm._e(),_c('v-dialog',{ref:("screen_item_date_time_picker_dialog_" + index),refInFor:true,attrs:{"data-cy":"4082","max-width":"300","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(localScreenItem.type === _vm.$CONSTANTS.WIDGET.DATE_TIME_PICKER)?_c('themis-input',{ref:("themis_input_" + index),refInFor:true,attrs:{"data-cy":"1409","p-label-class":"secondary--text","p-label":_vm.getScreenItemLabel(localScreenItem),"p-rules":_vm.getScreenItemRules(localScreenItem),"p-name":_vm.getScreenItemName(localScreenItem)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',_vm._g({ref:("screen_item_date_time_picker_" + index),refInFor:true,attrs:{"data-cy":"1344","value":_vm.formateDateTime(localScreenItem.value),"placeholder":_vm.getScreenItemPlaceholder(localScreenItem),"append-icon":"mdi-calendar-clock-outline","color":"secondary","error-messages":errors,"outlined":"","readonly":""},on:{"focus":onFocus,"blur":onBlur,"click":function($event){localScreenItem.showDateTimePickerDialog = true}}},on))]}}],null,true)}):_vm._e()]}}],null,true),model:{value:(localScreenItem.showDateTimePickerDialog),callback:function ($$v) {_vm.$set(localScreenItem, "showDateTimePickerDialog", $$v)},expression:"localScreenItem.showDateTimePickerDialog"}},[_c('themis-date-time-picker',{ref:("screen_item_date_time_picker_value_" + index),refInFor:true,attrs:{"data-cy":"1410"},on:{"close":function($event){localScreenItem.showDateTimePickerDialog = false},"save":function($event){localScreenItem.showDateTimePickerDialog = false}},model:{value:(localScreenItem.value),callback:function ($$v) {_vm.$set(localScreenItem, "value", $$v)},expression:"localScreenItem.value"}})],1),(localScreenItem.type === _vm.$CONSTANTS.WIDGET.TEXTAREA)?_c('themis-input',{ref:("themis_input_" + index),refInFor:true,staticClass:"mb-4",attrs:{"data-cy":"1403","p-label-class":"secondary--text","p-label":_vm.getScreenItemLabel(localScreenItem),"p-rules":_vm.getScreenItemRules(localScreenItem),"p-name":_vm.getScreenItemName(localScreenItem)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-textarea',{ref:("screen_item_text_area_" + index),refInFor:true,attrs:{"data-cy":"1356","placeholder":_vm.getScreenItemPlaceholder(localScreenItem),"color":"secondary","error":!!errors.length,"error-messages":errors,"counter":"50000","hide-details":!_vm.showTextAreaCounter && !errors.length,"outlined":"","auto-grow":""},on:{"focus":function($event){return _vm.handleTextAreaOnFocusEvent(onFocus)},"blur":onBlur},scopedSlots:_vm._u([{key:"counter",fn:function(ref){
var props = ref.props;
return [_c('span',{staticClass:"text-body-2",attrs:{"data-cy":"1411"}},[_vm._v(_vm._s(_vm.$t('1154', { value: props.value, max: props.max })))])]}}],null,true),model:{value:(localScreenItem.value),callback:function ($$v) {_vm.$set(localScreenItem, "value", $$v)},expression:"localScreenItem.value"}})]}}],null,true)}):_vm._e(),(localScreenItem.type === _vm.$CONSTANTS.WIDGET.TEXT_FIELD)?_c('themis-input',{ref:("themis_input_" + index),refInFor:true,attrs:{"data-cy":"1402_{localScreenItem.key}","p-label-class":"secondary--text","p-label":_vm.getScreenItemLabel(localScreenItem),"p-rules":_vm.getScreenItemRules(localScreenItem),"p-name":_vm.getScreenItemName(localScreenItem)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:("screen_item_text_field_" + index),refInFor:true,attrs:{"data-cy":"2901","placeholder":_vm.getScreenItemPlaceholder(localScreenItem),"color":"secondary","error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(localScreenItem.value),callback:function ($$v) {_vm.$set(localScreenItem, "value", $$v)},expression:"localScreenItem.value"}})]}}],null,true)}):_vm._e(),(localScreenItem.type === _vm.$CONSTANTS.WIDGET.COMBO_BOX)?_c('themis-input',{ref:("themis_input_" + index),refInFor:true,attrs:{"data-cy":"5510","p-rules":_vm.getScreenItemRules(localScreenItem),"p-label":_vm.getScreenItemLabel(localScreenItem),"p-label-class":"secondary--text","p-name":_vm.getScreenItemName(localScreenItem)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-combobox',{ref:("screen_item_combobox_" + index),refInFor:true,staticClass:"mt-1",attrs:{"data-cy":"5511","hide-no-data":!localScreenItem.value,"placeholder":_vm.$t('604'),"clear-icon":"mdi-close-circle-outline","color":"secondary","items":localScreenItem.items,"error-messages":errors,"outlined":"","chips":"","multi-line":"","multiple":"","deletable-chips":"","persistent-placeholder":"","clearable":""},on:{"focus":function($event){return _vm.handleComboboxInputOnFocusEvent(onFocus)},"blur":function($event){return _vm.handleComboboxInputOnBlurEvent(onBlur)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{attrs:{"data-cy":"504"}},[_c('span',{staticClass:"text-body-2 info--text",attrs:{"data-cy":"505"}},[_vm._v(_vm._s(_vm.$t("606")))])])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"primary","close":_vm.isComboboxInputFocussed,"close-icon":"mdi-close","label":"","small":""},on:{"click:close":function($event){return _vm.removeComboboxItem(item,localScreenItem)}}},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(localScreenItem.value),callback:function ($$v) {_vm.$set(localScreenItem, "value", $$v)},expression:"localScreenItem.value"}})]}}],null,true)}):_vm._e(),(localScreenItem.key === _vm.$CONSTANTS.WIDGET.ISSUE_FIELD)?_c('issue-fields',{key:localScreenItem.field.issueFieldId,attrs:{"data-cy":("4191_" + (localScreenItem.field.fieldId)),"p-local-screen-item":localScreenItem,"p-issue-field":localScreenItem.field,"p-is-option-list-cascaded":localScreenItem.field.isOptionListCascaded,"p-option-list-items":localScreenItem.field.optionListItems,"p-issue-field-values":localScreenItem.field.issueFieldValues,"p-read-only":localScreenItem.field.readOnly},on:{"updateIssueFieldValue":function (value) { return _vm.updateIssueFieldValue(value, localScreenItem); }}}):_vm._e()]})],2)],1),_c('v-card-actions',{staticClass:"justify-end mr-2 mb-1",attrs:{"data-cy":"3514"}},[_c('v-btn',{ref:"button_cancel",staticClass:"text-bold",attrs:{"data-cy":"2033","disabled":_vm.pIsTransitionInProgress,"text":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t('34')))]),_c('v-btn',{ref:"button_submit",staticClass:"ml-6 px-4",attrs:{"data-cy":"2034","color":"primary","type":"submit","loading":_vm.pIsTransitionInProgress}},[_vm._v(_vm._s(_vm.pSubmitButton))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }