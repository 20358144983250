import ThemisReportMessages from "@/components/report/messages"
import ThemisReportMetadata from "@/components/report/metadata"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import ThemisFile from "@/components/shared/file"
import ThemisLogs from "@/components/shared/logs"
import ThemisIssueDocuments from "@/components/issue/documents"
import ThemisIssueFormInstance from "@/components/issue/issue-form-instance"
import ThemisReportIntakeForms from "@/components/report/intake-forms"
import ThemisIssueLinks from "@/components/issue/issue-links"
import ThemisTasks from "@/components/issue/tasks"
import { compareDates } from "@/utils"
import { mapActions, mapGetters } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import { convertDaysToDuration } from "@/utils"
import {
  ISSUE_DOCUMENTS_TYPES,
  ISSUE_TYPES,
  DEFAULT_DATA_RETENTION_PERIODS,
  ISSUE_STATUS,
  TABLE_NAMES,
  DATA_RETENTION_STATUS,
  FIELD_TYPES,
  REPORT_CONTENTS_TO_BE_CLEARED
} from "@/constants"

export default {
  name      : "Issue",
  components: {
    ThemisDecision,
    ThemisInput,
    ThemisFile,
    ThemisLogs,
    ThemisIssueDocuments,
    ThemisReportMetadata,
    ThemisReportIntakeForms,
    ThemisReportMessages,
    ThemisIssueFormInstance,
    ThemisIssueLinks,
    ThemisTasks
  },
  data: () => ({
    localIssue                           : undefined,
    canViewSummary                       : false,
    canUpdateSummary                     : false,
    canViewDescription                   : false,
    canUpdateDescription                 : false,
    canUpdateDataRetentionPeriod         : false,
    canViewReceivedOn                    : false,
    canViewStatus                        : false,
    isSendingMessage                     : false,
    isMessageSent                        : false,
    activityTab                          : null,
    isRemoveCommentDialogOpen            : false,
    commentToBeRemoved                   : undefined,
    newLabel                             : undefined,
    expandMessages                       : false,
    canViewAnonymisation                 : false,
    canViewReportStatus                  : false,
    currentExpandedExpansionPanels       : [],
    defaultExpandedExpansionPanels       : [],
    displayDescriptionSaveAndCancelButton: false,
    showAddIssueFormTemplateDialog       : false,
    selectedIssueFormTemplate            : null,
    showRemoveFormInstanceDialog         : false,
    formInstanceToBeRemoved              : undefined,
    selectedDataRetentionPeriod          : undefined,
    showSetDataRetentionPeriodDialog     : false,
    showClearReportContentDialog         : false,
    canClearReportContent                : false,
    localReportContentsToBeCleared       : [],
    newComment                           : {
      value                : undefined,
      mentionComments      : [],
      showUserList         : false,
      currentCursorPosition: 0,
      currentAtPosition    : 0,
      atStartPosition      : 0,
      menuPosition         : { x: 0, y: 0 },
      textAreaElement      : undefined,
      userNotAvailable     : false
    },
    addNewTask: false
  }),
  computed: {
    ...mapGetters({
      loggedInUser                      : "auth/loggedInUser",
      accesses                          : "accesses/accesses",
      languages                         : "languages/languages",
      usersIncludingDeletedUsers        : "users/usersIncludingDeletedUsers",
      groups                            : "groups/groups",
      domains                           : "domains/domains",
      configurations                    : "configurations/configurations",
      issues                            : "issues/issues",
      issueDocuments                    : "issueDocuments/issueDocuments",
      logsOfIssues                      : "issues/logsOfIssues",
      isUpdatingSummary                 : "issues/isUpdatingSummary",
      isUpdatingDescription             : "issues/isUpdatingDescription",
      statuses                          : "issueStatuses/issueStatuses",
      resolutions                       : "issueResolutions/issueResolutions",
      reports                           : "reports/reports",
      isClearingReportContent           : "reports/isClearingReportContent",
      isReportContentCleared            : "reports/isReportContentCleared",
      isReportStatusUpdated             : "reports/isStatusUpdated",
      isUpdatingReportStatus            : "reports/isUpdatingStatus",
      channels                          : "channels/channels",
      messages                          : "messages/messages",
      isMessageAdded                    : "messages/isMessageAdded",
      isMessageStatusUpdated            : "messages/isStatusUpdated",
      isUpdatingMessageStatus           : "messages/isUpdatingStatus",
      isMessageRemoved                  : "messages/isMessageRemoved",
      isRemovingMessage                 : "messages/isRemovingMessage",
      messageItems                      : "messageItems/messageItems",
      isUpdatingMessageItemValue        : "messageItems/isUpdatingValue",
      translations                      : "translations/translations",
      isUpdatingTranslationStatus       : "translations/isUpdatingStatus",
      isUpdatingDataRetainedUntil       : "issues/isUpdatingDataRetainedUntil",
      isDataRetainedUntilUpdated        : "issues/isDataRetainedUntilUpdated",
      isTranslationStatusUpdated        : "translations/isStatusUpdated",
      isAddingTranslation               : "translations/isAddingTranslation",
      isTranslationAdded                : "translations/isTranslationAdded",
      commentsOfIssues                  : "issues/commentsOfIssues",
      isAddingComment                   : "issues/isAddingComment",
      isCommentAdded                    : "issues/isCommentAdded",
      isRemovingComment                 : "issues/isRemovingComment",
      isCommentRemoved                  : "issues/isCommentRemoved",
      isAddingIssueDocument             : "issueDocuments/isAddingIssueDocument",
      isRemovingIssueDocument           : "issueDocuments/isRemovingIssueDocument",
      isIssueDocumentRemoved            : "issueDocuments/isIssueDocumentRemoved",
      issueViewPolicies                 : "accessControl/issueViewPolicies",
      issueUpdatePolicies               : "accessControl/issueUpdatePolicies",
      issueAddDocumentsPolicies         : "accessControl/issueAddDocumentsPolicies",
      issueDocumentRemovePolicies       : "accessControl/issueDocumentRemovePolicies",
      issueAddCommentPolicies           : "accessControl/issueAddCommentPolicies",
      issueRemoveCommentPolicies        : "accessControl/issueRemoveCommentPolicies",
      reportViewPolicies                : "accessControl/reportViewPolicies",
      reportUpdatePolicies              : "accessControl/reportUpdatePolicies",
      reportContentClearPolicies        : "accessControl/reportContentClearPolicies",
      reportAddMessagesPolicies         : "accessControl/reportAddMessagesPolicies",
      messageUpdatePolicies             : "accessControl/messageUpdatePolicies",
      messageRemovePolicies             : "accessControl/messageRemovePolicies",
      translationUpdatePolicies         : "accessControl/translationUpdatePolicies",
      messageItemAddTranslationsPolicies: "accessControl/messageItemAddTranslationsPolicies",
      formInstances                     : "formInstances/formInstances",
      issueFormTemplates                : "formTemplates/issueFormTemplates",
      fieldsV2                          : "fields/fieldsV2",
      formTemplateConfigurations        : "formTemplateConfigurations/formTemplateConfigurations",
      isAddingIssueFormInstance         : "formInstances/isAddingIssueFormInstance",
      isIssueFormInstanceAdded          : "formInstances/isIssueFormInstanceAdded",
      issueAddFormInstancesPolicies     : "accessControl/issueAddFormInstancesPolicies",
      optionListItems                   : "optionListItems/optionListItems",
      isLoadingIssueDocuments           : "issueDocuments/isLoadingIssueDocuments",
      isLoadingFormInstances            : "formInstances/isLoadingFormInstances",
      isLoadingMessages                 : "messages/isLoadingMessages",
      isLoadingMessageItems             : "messageItems/isLoadingMessageItems",
      isFormInstanceRemoved             : "formInstances/isFormInstanceRemoved",
      isRemovingFormInstance            : "formInstances/isRemovingFormInstance",
      isUpdatingFormInstance            : "formInstances/isUpdatingFormInstance",
      isFormInstanceUpdated             : "formInstances/isFormInstanceUpdated",
      formInstanceUpdatePolicies        : "accessControl/formInstanceUpdatePolicies",
      formInstanceRemovePolicies        : "accessControl/formInstanceRemovePolicies",
      issuePrintPolicies                : "accessControl/issuePrintPolicies",
      issueLinkAddPolicies              : "accessControl/issueLinkAddPolicies",
      issueLinkRemovePolicies           : "accessControl/issueLinkRemovePolicies",
      issueLinks                        : "issueLinks/issueLinks",
      isLoadingIssueLinks               : "issueLinks/isLoadingIssueLinks",
      isIssueLinkAdded                  : "issueLinks/isIssueLinkAdded",
      isAddingIssueLinks                : "issueLinks/isAddingIssueLinks",
      isIssueLinkRemoved                : "issueLinks/isIssueLinkRemoved",
      isRemovingIssueLink               : "issueLinks/isRemovingIssueLink",
      loggedInUserPolicies              : "accessControl/loggedInUserPolicies",
      issueAddTaskPolicies              : "accessControl/issueAddTaskPolicies",
      dataRetentionPeriods              : "dataRetentionPeriods/dataRetentionPeriods",
      isAiAnonymisationEnabled          : "configurations/isAiAnonymisationEnabled",
      isIssueLinksEnabled               : "configurations/isIssueLinksEnabled",
      isTasksEnabled                    : "configurations/isTasksEnabled",
      isIssueFormsEnabled               : "configurations/isIssueFormsEnabled",
      workflows                         : "workflows/workflows",
      workflowAssociations              : "workflowAssociations/workflowAssociations",
      types                             : "issueTypes/issueTypes",
      isTransitionDone                  : "transitions/isTransitionDone",
      isTransitionInProgress            : "transitions/isTransitionInProgress",
      groupsUsers                       : "groups/groupsUsers",
      isReporterIntakeFormsEnabled      : "configurations/isReporterIntakeFormsEnabled",
      isMentionCommentsEnabled          : "configurations/isMentionCommentsEnabled",
      isReplyTemplatesEnabled           : "configurations/isReplyTemplatesEnabled",
      users                             : "users/users",
      replyTemplates                    : "replyTemplates/replyTemplates"
    }),
    usersWithAllLevelAccess() {
      let usersWithAllLevelAccess = []
      for (const access of this.accesses) {
        if (this.issue.parentId) {
          if (access.criteria?.id === this.parentIssue.id && access.userId) {
            usersWithAllLevelAccess.push(access.userId)
          } else if (access.criteria?.id === this.parentIssue.id && access.groupId) {
            if (this.groupsUsers[access.groupId]) {
              usersWithAllLevelAccess = [...usersWithAllLevelAccess,
                ... this.groupsUsers[access.groupId]]
            }
          }
        }
        if (access.criteria?.id === this.issue?.id && access.userId) {
          usersWithAllLevelAccess.push(access.userId)
        } else if (access.criteria?.id === this.issue?.id && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithAllLevelAccess = [...usersWithAllLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      usersWithAllLevelAccess = [...usersWithAllLevelAccess, ... this.usersWithDomainLevelAccess]
      return [...new Set(usersWithAllLevelAccess)]
    },
    usersWithDomainLevelAccess() {
      let usersWithDomainLevelAccess = []
      for (const access of this.accesses) {
        const issue = this.issue.parentId ? this.parentIssue : this.issue
        if (access.criteria?.domainId === issue.domainId && access.userId) {
          usersWithDomainLevelAccess.push(access.userId)
        } else if (access.criteria?.domainId === issue.domainId && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithDomainLevelAccess = [...usersWithDomainLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      return [...new Set(usersWithDomainLevelAccess)]
    },
    parentIssue() {
      return this.issues.find(issue => issue.id === this.issue?.parentId)
    },
    isLargeAndUpScreen() {
      return this.$vuetify.breakpoint.lgAndUp
    },
    logsOfIssue() {
      let logsOfIssue = this.logsOfIssues[+this.$route.params.id]
      if (logsOfIssue) {
        logsOfIssue = logsOfIssue.sort((item1, item2) => {
          return compareDates(item2.createdAt, item1.createdAt)
        })
      }
      return logsOfIssue
    },
    commentsOfIssue() {
      const commentsOfIssue = this.commentsOfIssues[this.$route.params.id]
      if (commentsOfIssue?.length) {
        return commentsOfIssue.sort((firstComment, secondComment) => compareDates(
          firstComment.createdAt, secondComment.createdAt))
          .map(comment => {
            let startingPosition = 0
            const commentValues  = comment.mentionComments.sort(
              (firstMention, secondMention) => firstMention.position - secondMention.position)
              .map(mention => {
                const end   = mention.position
                const value = comment.value.substring(startingPosition, end)

                const user       = this.usersMap[mention.userId]
                const userName   = user.enabled ? user.name
                  : user.deletedAt
                    ? `${user.name}`
                    : `${user.name} ${this.$t("1131")}`
                startingPosition = end
                return { value, userName }
              })
            const remainingText = comment.value.substring(startingPosition)
            commentValues.push({ value: remainingText })

            return { ...comment, commentValues }
          })
      }
    },
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    resolutionsMap() {
      const resolutionsMap = new Object()
      for (const resolution of this.resolutions) {
        resolutionsMap[resolution.id] = resolution
      }
      return resolutionsMap
    },
    statusesMap() {
      const statusesMap = new Object()
      for (const status of this.statuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    translationsMap() {
      const translationsMap = new Object()
      for (const translation of this.translations) {
        translationsMap[translation.messageItemId] = translation
      }
      return translationsMap
    },
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    tasks() {
      const filteredIssues = this.issues.filter(issue => issue.parentId === this.issue.id)

      return filteredIssues.sort((first, second) => {
        return compareDates(first.createdAt, second.createdAt)
      })
    },
    latestTask() {
      return this.tasks[this.tasks.length - 1]
    },
    reportId() {
      return this.reports?.find(report => report.issueId === this.issue?.id)?.id
    },
    report() {
      let report = this.reports?.find(report => report.issueId === this.issue?.id)
      if (report) {
        const messages = this.messages?.filter(message =>
          message.reportId === report.id
        )
        report         = Object.assign({}, report, {
          messages
        })
        if (report?.messages?.length && this.messageItems) {
          const indexOfMessages = new Object()
          for (const [index, message] of report.messages.entries()) {
            indexOfMessages[message.id] = index
          }
          for (let messageItem of this.messageItems) {
            const indexOfMessage = indexOfMessages[messageItem.messageId]
            if (indexOfMessage !== undefined) {
              messageItem = Object.assign({}, messageItem, {
                language: this.languagesMap[messageItem.languageId]
              })
              if (report.messages[indexOfMessage].items) {
                report.messages[indexOfMessage].items.push(messageItem)
              } else {
                report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                  items: [messageItem]
                })
              }
            }
            if (messageItem.version === "original" && this.translationsMap[messageItem.id]) {
              report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                translation: this.translationsMap[messageItem.id]
              })
            }
          }
        }
      }
      return report
    },
    newMessagesFromReporter() {
      const newMessagesFromReporter = new Array()
      for (const message of this.report?.messages) {
        if (message.userId === null && message.status === this.$CONSTANTS.MESSAGE_STATUS.NEW) {
          newMessagesFromReporter.push(message)
        }
      }
      return newMessagesFromReporter
    },
    isReportOpen() {
      return this.report?.status === "new"
    },
    isReportAnonymised() {
      return this.report?.anonymisation === "done"
    },
    isReportAnonymisationScheduled() {
      return this.report.anonymisation === "scheduled"
    },
    reportFormInstances() {
      return this.formInstances.filter(formInstance => formInstance.reportId === this.report?.id)
    },
    issueFormInstances() {
      return this.formInstances
        .filter(formInstance => formInstance.issueId === this.issue?.id)
        .sort((formInstance1, formInstance2) => {
          return compareDates(formInstance1.createdAt, formInstance2.createdAt)
        })
    },
    documentsOfIssue() {
      return this.issueDocuments?.filter(issueDocument => issueDocument.issueId === +this.$route.params.id)
    },
    documentsOfReport() {
      const documentsOfReport = new Array()
      if (this.report?.messages) {
        for (const message of this.report.messages) {
          if (!message.userId && message.items) {
            for (const item of message.items) {
              if (!["text", "audio"].includes(item.type)) {
                documentsOfReport.push(item)
              }
            }
          }
        }
      }
      return documentsOfReport
    },
    documentsCount() {
      return this.documentsOfIssue.concat(this.documentsOfReport).length
    },
    channel() {
      const channel = this.channels?.find(channel => channel.id === this.report.channelId)
      if (channel) {
        if (channel.overrideTranslationPreference) {
          channel.effectiveTranslationPreference = channel.translationPreferenceId
        } else if (this.globalTranslationPreference) {
          channel.effectiveTranslationPreference = +this.globalTranslationPreference.value
        }
      }
      return channel
    },
    isSummaryChanged() {
      return this.issue?.summary !== this.localIssue?.summary
    },
    isDescriptionChanged() {
      return this.issue?.description !== this.localIssue?.description
    },
    globalTranslationPreference() {
      return this.configurations.find(configuration => configuration.key === "TRANSLATION")
    },
    currentIssueDocumentRemovePolicies() {
      return this.issueDocumentRemovePolicies.filter(issueDocumentRemovePolicy =>
        this.issueDocuments.find(issueDocument =>
          issueDocument.id === issueDocumentRemovePolicy.id
        )
      )
    },
    currentIssueAddDocumentsPolicies() {
      return this.issueAddDocumentsPolicies.find(issueAddDocumentsPolicy =>
        issueAddDocumentsPolicy.id === +this.$route.params.id
      )
    },
    currentReportViewPolicy() {
      return this.reportViewPolicies.find((reportViewPolicy =>
        reportViewPolicy.id === this.report?.id
      ))
    },
    canViewReport() {
      return !!this.currentReportViewPolicy?.select?.length
    },
    currentReportUpdatePolicy() {
      return this.reportUpdatePolicies.find((reportUpdatePolicy =>
        reportUpdatePolicy.id === this.report?.id
      ))
    },
    currentReportContentClearPolicies() {
      return this.reportContentClearPolicies.find(reportClearContentPolicy =>
        reportClearContentPolicy.id === this.report?.id
      )
    },
    currentReportAddMessagePolicy() {
      return this.reportAddMessagesPolicies.find(reportAddMessagesPolicy =>
        reportAddMessagesPolicy.id === this.report?.id
      )
    },
    currentReportMessageUpdatePolicies() {
      const currentReportMessageUpdatePolicies = []
      if (this.messages) {
        for (const message of this.messages) {
          const messageUpdatePolicy = this.messageUpdatePolicies.find(policy => policy.id === message.id)
          if (messageUpdatePolicy) {
            currentReportMessageUpdatePolicies.push(messageUpdatePolicy)
          }
        }
      }
      return currentReportMessageUpdatePolicies
    },
    currentReportMessageRemovePolicies() {
      const currentReportMessageRemovePolicies = []
      if (this.messages) {
        for (const message of this.messages) {
          const messageRemovePolicy = this.messageRemovePolicies.find(policy => policy.id === message.id)
          if (messageRemovePolicy) {
            currentReportMessageRemovePolicies.push(messageRemovePolicy)
          }
        }
      }
      return currentReportMessageRemovePolicies
    },
    canAddComment() {
      const currentIssueAddCommentPolicy = this.issueAddCommentPolicies.find(
        issueAddCommentPolicy => issueAddCommentPolicy.id === +this.$route.params.id
      )
      return currentIssueAddCommentPolicy?.set?.value !== undefined
    },
    canAddDocuments() {
      return this.currentIssueAddDocumentsPolicies?.set?.add !== undefined
    },
    canAddDataRetentionPeriod() {
      return this.localIssue.closedAt !== null && this.localIssue.dataRetentionStatus === null
        && this.canUpdateDataRetentionPeriod
    },
    currentIssuePrintPolicies() {
      return this.issuePrintPolicies.find(issuePrintPolicy =>
        issuePrintPolicy.id === +this.$route.params.id
      )
    },
    canPrintIssue() {
      return this.currentIssuePrintPolicies?.set?.print !== undefined
    },
    isIssueClosedAndNotScheduled() {
      return this.issue?.statusId === ISSUE_STATUS.CLOSED &&
        this.issue?.dataRetentionStatus === null
    },
    isDataRetentionAnonymised() {
      return this.issue?.dataRetentionStatus === DATA_RETENTION_STATUS.ANONYMISED
    },
    reportContentsToBeCleared() {
      const reportContentsToBeCleared = []
      for (const content of REPORT_CONTENTS_TO_BE_CLEARED) {
        reportContentsToBeCleared.push({
          ...content,
          label: this.$t(content.label),
          field: false
        })
      }
      for (const formInstance of this.reportFormInstances) {
        const shortTextFormInstanceFields = formInstance.formInstanceFields
          .filter(field => field.type === FIELD_TYPES.SHORT_TEXT.value)
        for (const field of shortTextFormInstanceFields) {
          reportContentsToBeCleared.push({
            value  : field.fieldId,
            label  : field.systemName,
            checked: false,
            field  : true
          })
        }
      }
      return reportContentsToBeCleared
    },
    isReportContentToBeCleared() {
      return !this.localReportContentsToBeCleared.some(item => item.checked)
    },
    showReportPanels() {
      return !!this.canViewReport && !!this.report
    },
    allowedIssueDocumentsTypes() {
      return Object.values(ISSUE_DOCUMENTS_TYPES)
    },
    numberOfFormInstances() {
      return this.reportFormInstances.length + this.issueFormInstances.length
    },
    anchorLinks() {
      const links = []
      if (this.showReportPanels) {
        links.push({
          id  : "report_overview_panel",
          name: this.$t("1201"),
          hash: "report_overview_panel"
        })
      }
      if (this.reportFormInstances.length && this.isReporterIntakeFormsEnabled) {
        for (const formInstance of this.reportFormInstances) {
          links.push({
            id  : formInstance.id,
            name: this.$t("800"),
            hash: `panel_report_intake_forms_${formInstance.id}`
          })
        }
      }
      const messages = this.messages?.filter(message => message.reportId === this.reportId)
      if (messages.length) {
        links.push({
          id  : "panel_messages",
          name: this.$t("1213", { count: messages.length }),
          hash: "panel_messages"
        })
      }
      links.push({ name: this.$t("297", { count: this.documentsCount }), hash: "documents_panel" })
      if (this.issueFormInstances.length && this.isIssueFormsEnabled) {
        for (const formInstance of this.issueFormInstances) {
          links.push({
            id  : formInstance.id,
            name: formInstance.formTemplateName,
            hash: `panel_issue_form_${formInstance.id}`
          })
        }
      }
      if (this.isIssueLinksEnabled) {
        links.push({ id: "issue_links", name: this.$t("1165", { count: this.linkedIssuesForTheIssue.length }), hash: "issue_links" })
      }
      if (this.isTasksEnabled) {
        links.push({ id: "tasks_panel", name: this.$t("1264", { count: this.tasks.length }), hash: "tasks_panel" })
      }
      links.push({ id: "activity", name: this.$t("593"), hash: "activity" })
      return links
    },
    scrollToHash() {
      const isHashHasTemp = this.$route.hash.startsWith("#temp")
      if (this.isDataLoadingComplete) {
        return this.$route.hash
      }
      if (isHashHasTemp) {
        return this.$route.hash
      }
    },
    issueFormTemplatesAddTableHeaders() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATES, this.$t.bind(this))
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.formTemplateConfigurations) {
        let fields = [this.fieldsMap.get(configuration.fieldId)]
        if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return issueFormTemplateFieldsMap
    },
    issueFormTemplatesAddTableItems() {
      return this.issueFormTemplates?.sort((issueFormTemplate1, issueFormTemplate2) =>
        compareDates(issueFormTemplate1.createdAt, issueFormTemplate2.createdAt)
      ).map(issueFormTemplate => {
        const fieldNames = this.getIssueFormTemplateFieldNames(issueFormTemplate.id)
        return {
          id    : issueFormTemplate.id,
          name  : issueFormTemplate.name,
          fields: fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
        }
      })
    },
    issueFormTemplatesAddTableHeight() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with header height

      if (this.issueFormTemplatesAddTableItems.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.issueFormTemplatesAddTableItems.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.issueFormTemplatesAddTableItems.length * dataRowHeight
      }

      return heightOfTable
    },
    currentIssueAddFormInstancesPolicies() {
      return this.issueAddFormInstancesPolicies.find(issueAddFormInstancesPolicy =>
        issueAddFormInstancesPolicy.id === +this.$route.params.id
      )
    },
    canAddIssueFormTemplate() {
      return this.currentIssueAddFormInstancesPolicies?.set?.add !== undefined
    },
    isDataLoadingComplete() {
      return !this.isLoadingFormInstances && !this.isLoadingMessages &&
        !this.isLoadingIssueDocuments && !this.isLoadingMessageItems &&
        !this.isLoadingIssueLinks
    },
    displayMoreActionMenu() {
      return this.isACase && (this.canAddDataRetentionPeriod || this.canAddDocuments ||
        this.canPrintIssue || this.canClearReportContent)
    },
    issueViewPolicy() {
      return this.issueViewPolicies.find(issueViewPolicy => issueViewPolicy.id === +this.$route.params.id)
    },
    issueUpdatePolicy() {
      return this.issueUpdatePolicies.find(issueUpdatePolicy => issueUpdatePolicy.id === +this.$route.params.id)
    },
    linkedIssuesForTheIssue() {
      return this.issueLinks.filter(issueLink =>
        issueLink.fromId === this.issue?.id || issueLink.toId === this.issue?.id
      )
    },
    isACase() {
      return this.issue.typeId === ISSUE_TYPES[0].id
    },
    canAddTask() {
      return this.issueAddTaskPolicies.find(issueAddTaskPolicy =>
        issueAddTaskPolicy.id === +this.$route.params.id)?.set?.add !== undefined
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod) =>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const retentionPeriod of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(retentionPeriod)
        dataRetentionPeriods.push({
          days    : retentionPeriod,
          duration: this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      return dataRetentionPeriods
    },
    usersWithAccess() {
      return this.users.filter(user => this.usersWithAllLevelAccess.includes(user.id)).filter(user => user.enabled)
    },
    filteredUsers() {
      if (!this.newComment.showUserList) return []

      const searchQuery = this.newComment.value?.slice(
        this.newComment.currentAtPosition + 1, this.newComment.currentCursorPosition
      ) ?? ""

      const filteredUsers = this.usersWithAccess.filter(user =>
        user.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      )

      if (!filteredUsers.length) {
        this.newComment.userNotAvailable = true
        return [{ name: this.$t("1387") }]
      }
      return filteredUsers
    },
    menuHeight() {
      switch (this.filteredUsers.length) {
        case 1:
          return 65
        case 2:
          return 115
        default:
          return 160
      }
    },
    sortedReplyTemplates() {
      return this.replyTemplates.sort((first, second) => first.name.localeCompare(second.name))
    }
  },
  methods: {
    ...mapActions({
      updateIssue         : "issues/updateIssue",
      updateReport        : "reports/updateReport",
      updateMessage       : "messages/updateMessage",
      addMessage          : "messages/addMessage",
      addMessageItem      : "messageItems/addMessageItem",
      updateMessageItem   : "messageItems/updateMessageItem",
      loadMessageItems    : "messageItems/loadMessageItems",
      removeMessage       : "messages/removeMessage",
      notify              : "shared/notify",
      downloadReporterFile: "messageItems/downloadFile",
      downloadUserFile    : "issueDocuments/downloadFile",
      addIssueDocument    : "issueDocuments/addIssueDocument",
      removeIssueDocument : "issueDocuments/removeIssueDocument",
      addTranslation      : "translations/addTranslation",
      loadTranslations    : "translations/loadTranslations",
      updateTranslation   : "translations/updateTranslation",
      addComment          : "issues/addComment",
      removeComment       : "issues/removeComment",
      addIssueFormInstance: "formInstances/addIssueFormInstance",
      removeFormInstance  : "formInstances/removeFormInstance",
      updateFormInstance  : "formInstances/updateFormInstance",
      addIssueLinks       : "issueLinks/addIssueLinks",
      removeIssueLink     : "issueLinks/removeIssueLink",
      makeTransition      : "transitions/makeTransition",
      clearReportContents : "reports/clearReportContents"
    }),
    handleReportContentToBeCleared() {
      const contentToBeCleared = {
        fields  : [],
        reportId: this.report.id
      }
      for (const content of this.localReportContentsToBeCleared) {
        if (content.field) {
          contentToBeCleared.fields.push({
            id   : content.value,
            value: content.checked
          })
        } else {
          contentToBeCleared[content.value] = content.checked
        }
      }

      this.clearReportContents(contentToBeCleared)
    },
    handleCancelReportContent() {
      this.showClearReportContentDialog = false
      this.resetLocalReportContentsToBeCleared()
    },
    resetLocalReportContentsToBeCleared() {
      this.localReportContentsToBeCleared = this.localReportContentsToBeCleared.map(content => {
        content.checked = false
        return content
      })
    },
    handleAddComment() {
      const comment = {
        value          : "",
        mentionComments: []
      }
      if (this.isMentionCommentsEnabled) {
        this.newComment.mentionComments = this.newComment.mentionComments.map(user => {
          const position        = this.newComment.value.indexOf(user.userName) - 1
          this.newComment.value = this.newComment.value.replace(`@${user.userName}`, "")
          return { userId: user.userId, position }
        }).filter(user => user.position >= 0)

        comment.mentionComments = this.newComment.mentionComments
      }
      comment.value = this.newComment.value
      this.addComment({ id: this.localIssue.id, comment })
    },
    updateMenuPosition() {
      const textUpToCursor = this.newComment.value.substring(0, this.newComment.currentAtPosition)
      const textareaRect   = this.textAreaElement.getBoundingClientRect()

      const lines              = textUpToCursor.split("\n")
      const currentLine        = lines[lines.length - 1]
      const charsInCurrentLine = currentLine.length

      const cursorX = textareaRect.left + (charsInCurrentLine * 7)// Assuming character width id 7px
      const cursorY = textareaRect.top + ((lines.length - 1) * 28)// Assuming line height of 20px

      this.newComment.menuPosition.x = cursorX
      this.newComment.menuPosition.y = cursorY - this.menuHeight // Assuming menu height is 160px
    },
    handleCommentKeyUpEvent(event) {
      const value                           = event.target.value
      this.textAreaElement                  = event.target
      this.newComment.currentCursorPosition = event.target.selectionStart
      this.newComment.currentAtPosition     = value.lastIndexOf("@", this.newComment.currentCursorPosition)
      const isSpaceBeforeAt                 = this.newComment.currentAtPosition === 0
        || value.charAt(this.newComment.currentAtPosition - 1) === " "
        || value.charAt(this.newComment.currentAtPosition - 1) === "\n"

      if (this.filteredUsers.length) {
        this.updateMenuPosition()
      }

      if (event.key === "@" && isSpaceBeforeAt) {
        this.newComment.showUserList    = true
        this.newComment.atStartPosition = this.newComment.currentAtPosition

      } else if (event.key === " " && this.newComment.userNotAvailable && isSpaceBeforeAt) {

        const userName = value.substring(
          this.newComment.currentAtPosition + 1, this.newComment.currentCursorPosition - 1
        )
        const user     = this.usersWithAccess.find(user => {
          return user.name.toLowerCase() === userName.toLowerCase()
        })

        if (user) {
          this.addMention(user)
        }
      } else if (this.newComment.atStartPosition > this.newComment.currentAtPosition) {
        this.newComment.showUserList = false
      }
    },
    addMention(user) {
      const beforeText = this.newComment.value.substring(0, this.newComment.currentAtPosition + 1)
      const afterText  = this.newComment.value.substring(this.newComment.currentCursorPosition)

      this.newComment.value = `${beforeText}${user.name} ${afterText}`
      this.newComment.mentionComments.push({
        userId  : user.id,
        userName: user.name
      })
      this.newComment.showUserList = false

      this.$nextTick(() => {
        if (this.textAreaElement) {
          this.textAreaElement.focus()
          const newCursorPosition = this.newComment.currentAtPosition + user.name.length + 2
          this.textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition)
        }
      })
    },
    resetNewComment() {
      this.newComment.value                 = undefined
      this.newComment.mentionComments       = []
      this.newComment.currentCursorPosition = 0
      this.newComment.currentAtPosition     = 0
      this.newComment.atStartPosition       = 0
      this.newComment.userNotAvailable      = false
      this.newComment.menuPosition          = { x: 0, y: 0 }
    },
    updateSummary() {
      if (this.isSummaryChanged) {
        if (!this.localIssue.summary) {
          this.localIssue.summary = ""
        }
        this.updateIssue({
          id     : this.localIssue.id,
          summary: this.localIssue.summary
        })
      }
    },
    handleSummaryInputOnBlurEvent(onBlur) {
      onBlur()
      this.updateSummary()
    },
    handleSummaryInputOnEnter() {
      this.$refs.input_summary.blur()
    },
    handleDescriptionOnFocusEvent(onFocus) {
      onFocus()
      this.displayDescriptionSaveAndCancelButton = true
    },
    handleNewMessage(language) {
      this.addMessage({
        reportId: this.report.id,
        item    : {
          type      : "text",
          value     : "",
          languageId: language.id
        }
      })
    },
    handleDiscardMessage(message) {
      this.removeMessage(message.id)
    },
    sendMessage(message, messageItem) {
      this.isSendingMessage = true
      this.updateMessage({
        id    : message.id,
        status: "new",
        item  : messageItem
      })
    },
    startCommentRemovalProcess(comment) {
      this.isRemoveCommentDialogOpen = true
      this.commentToBeRemoved        = comment
    },
    handleAddIssueDocument(file) {
      this.addIssueDocument({
        issueId: this.localIssue.id,
        file
      })
    },
    canRemoveComment(commentId) {
      const issueRemoveCommentPolicy = this.issueRemoveCommentPolicies.find(issueRemoveCommentPolicy =>
        issueRemoveCommentPolicy.id === +this.$route.params.id
      )
      if (issueRemoveCommentPolicy?.set?.commentId) {
        return !issueRemoveCommentPolicy.set.commentId.length ||
          issueRemoveCommentPolicy.set.commentId.includes(commentId)
      }
    },
    handleIssueDocumentRemoved(documentRemoved) {
      this.notify({
        type      : "success",
        text      : "662",
        parameters: {
          name: documentRemoved.name
        }
      })
    },
    toggleExpandCollapseMessages() {
      if (!this.expandMessages && this.newMessagesFromReporter?.length) {
        for (const message of this.newMessagesFromReporter) {
          const messageUpdatePolicy = this.currentReportMessageUpdatePolicies.find(
            messageUpdatePolicy => messageUpdatePolicy.id === message.id)
          if (messageUpdatePolicy?.set?.status) {
            if (messageUpdatePolicy.set.status.includes("seen")) {
              this.updateMessage({
                id    : message.id,
                status: "seen"
              })
            }
          }
        }
      }
      this.expandMessages = !this.expandMessages
    },
    handleCancelSaveDescription() {
      this.localIssue.description                = this.issue.description
      this.displayDescriptionSaveAndCancelButton = false
    },
    handleSaveDescription() {
      if (!this.localIssue.description) {
        this.localIssue.description = ""
      }
      this.updateIssue({
        id         : this.localIssue.id,
        description: this.localIssue.description
      })
    },
    handleAddDocuments() {
      const expansionPanels                   = this.$refs.expansion_panels
      const indexOfAddDocumentsExpansionPanel = expansionPanels.items.findIndex(expansionPanel =>
        expansionPanel.$attrs["data-panel-name"] === "add_documents"
      )
      this.currentExpandedExpansionPanels.push(indexOfAddDocumentsExpansionPanel)

      const addDocumentsExpansionPanel = this.$refs.documents_panel
      this.$vuetify.goTo(addDocumentsExpansionPanel, {
        duration: 500,
        offset  : 0,
        easing  : "easeInOutCubic"
      })
      this.$refs.issue_documents.openFileExplorerToSelectDocuments()
    },
    getIssueFormTemplateFieldNames(formTemplateId) {
      return this.issueFormTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    selectIssueFormTemplate(item) {
      this.selectedIssueFormTemplate = item
    },
    async issueFormTemplatesAddHandler() {
      const payload = {
        issueId       : this.issue?.id,
        formTemplateId: this.selectedIssueFormTemplate.id
      }
      this.addIssueFormInstance(payload)
    },
    getClassForFieldRow(item) {
      let classForFieldRow = ""
      if (item.id === this.selectedIssueFormTemplate?.id) {
        classForFieldRow += "blue lighten-4"
      }
      return classForFieldRow
    },
    handleAnchorLink(link) {
      this.$router.push({ name: "issue", hash: "#temp_" + link.hash })
    },
    handleRemoveFormInstanceDialog(formInstanceToBeRemoved) {
      this.formInstanceToBeRemoved      = formInstanceToBeRemoved
      this.showRemoveFormInstanceDialog = true
    },
    handleRemoveFormInstance() {
      this.removeFormInstance(this.formInstanceToBeRemoved.id)
    },
    handleIssuePrint() {
      this.$router.push({ name: "issue-print", params: { id: this.issue?.id } })
    },
    handleAddIssuesLinks(issuesToLink) {
      this.addIssueLinks(issuesToLink)
    },
    handleAddTask(task) {
      this.addNewTask = true
      this.makeTransition({
        id  : task.transitionId,
        data: {
          ...task.data,
          parentId: +this.$route.params.id
        }
      })
    },
    handleIssueLinkRemoved(linkedIssue) {
      this.notify({
        type      : "success",
        text      : "1253",
        parameters: {
          id     : linkedIssue.issueId,
          summary: linkedIssue.text
        }
      })
    },
    handleSetDataRetentionPeriod() {
      this.updateIssue({
        id               : this.localIssue.id,
        dataRetainedUntil: this.$moment().add(this.selectedDataRetentionPeriod, "days").format("YYYY-MM-DD")
      })
    },
    handleCancelSetDataRetentionPeriod() {
      this.selectedDataRetentionPeriod      = undefined
      this.showSetDataRetentionPeriodDialog = false
    }
  },
  watch: {
    reportContentsToBeCleared: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue.length) {
          this.localReportContentsToBeCleared = [...newValue]
        }
      }
    },
    isReportContentCleared: {
      handler: function(newValue) {
        if (newValue) {
          this.showClearReportContentDialog = false
          this.notify({
            type: "success",
            text: "1578"
          })
          this.resetLocalReportContentsToBeCleared()
        }
      }
    },
    currentReportContentClearPolicies: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canClearReportContent = this.isDataRetentionAnonymised && !!newValue.set?.fields
          && !!newValue.set?.messageContent && !!newValue.set?.reportDocument
        }
      }
    },
    currentReportViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewAnonymisation = newValue.select.includes("anonymisation")
          this.canViewReportStatus  = newValue.select.includes("status")
        }
      }
    },
    issue: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalIssueNotSet          = !this.localIssue
          const isLocalIssueSetButDifferent = !isLocalIssueNotSet && (
            this.localIssue.id !== newValue.id
            || this.localIssue.summary !== newValue.summary
            || this.localIssue.description !== newValue.description
          )
          if (isLocalIssueNotSet || isLocalIssueSetButDifferent) {
            this.localIssue = { ...newValue }
          }
        }
      }
    },
    issueViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewSummary     = newValue.select.includes("summary")
          this.canViewDescription = newValue.select.includes("description")
          this.canViewStatus      = newValue.select.includes("statusId")
        }
      }
    },
    issueUpdatePolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canUpdateSummary             = newValue.set.summary !== undefined
          this.canUpdateDescription         = newValue.set.description !== undefined
          this.canUpdateDataRetentionPeriod = newValue.set.dataRetainedUntil !== undefined
        } else {
          this.canUpdateSummary             = undefined
          this.canUpdateDescription         = undefined
          this.canUpdateDataRetentionPeriod = undefined
        }
      }
    },
    isUpdatingMessageStatus: {
      handler: function(newValue) {
        if (this.isSendingMessage) {
          if (!newValue) {
            this.isSendingMessage = newValue
            this.isMessageSent    = this.isMessageStatusUpdated
            this.loadTranslations({
              issueId: this.issue?.id
            })
            this.loadMessageItems({
              messageId: (this.report.messages.map(message => message.id)).toString()
            })
          }
        }
      }
    },
    isReportStatusUpdated: {
      handler: function(newValue) {
        if (newValue && this.report?.status === "closed") {
          this.notify({
            type      : "success",
            text      : "437",
            parameters: {
              id: this.localIssue.id
            }
          })
        }
      }
    },
    isMessageRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "accent",
            text: "439"
          })
        }
      }
    },
    isCommentAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.resetNewComment()
        }
      }
    },
    isCommentRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "602"
          })
          this.isRemoveCommentDialogOpen = false
          this.commentToBeRemoved        = undefined
        }
      }
    },
    isRemovingComment: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_COMMENT.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_COMMENT.pActions[1].buttonProps.loading  = newValue
      }
    },
    isDataRetainedUntilUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.selectedDataRetentionPeriod      = undefined
          this.showSetDataRetentionPeriodDialog = false
        }
      }
    },
    isUpdatingDataRetainedUntil: {
      handler: function(newValue) {
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[0].buttonProps.disabled = newValue
      }
    },
    selectedDataRetentionPeriod: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[1].buttonProps.disabled = !newValue
      }
    },
    isUpdatingDescription: {
      handler: function(newValue) {
        if (!newValue) {
          this.displayDescriptionSaveAndCancelButton = false
        }
      }
    },
    showReportPanels: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          await this.$nextTick()
          const expansionPanels = this.$refs.expansion_panels
          if (expansionPanels) {
            const indexOfReportOverviewExpansionPanel = expansionPanels.items.findIndex(expansionPanel =>
              expansionPanel.$attrs["data-panel-name"] === "report_overview"
            )
            this.currentExpandedExpansionPanels.push(indexOfReportOverviewExpansionPanel)
            this.defaultExpandedExpansionPanels.push(indexOfReportOverviewExpansionPanel)
          }
        }
      }
    },
    numberOfFormInstances: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          await this.$nextTick()
          const expansionPanels = this.$refs.expansion_panels
          if (expansionPanels) {
            for (const [indexOfExpansionPanel, expansionPanel] of expansionPanels.items.entries()) {
              if (["report_intake_forms", "issue_form"].includes(expansionPanel.$attrs["data-panel-name"]) &&
                !this.currentExpandedExpansionPanels.includes(indexOfExpansionPanel) &&
                !this.defaultExpandedExpansionPanels.includes(indexOfExpansionPanel)
              ) {
                this.currentExpandedExpansionPanels.push(indexOfExpansionPanel)
                this.defaultExpandedExpansionPanels.push(indexOfExpansionPanel)
              }
            }
          }
        }
      }
    },
    scrollToHash: {
      immediate: true,
      handler  : async function(newValue) {
        await this.$nextTick()
        await this.$nextTick()
        const isHashHasTemp = newValue?.startsWith("#temp")
        if (newValue) {
          if (isHashHasTemp) {
            this.$router.push({
              name: "issue", hash: "#" + newValue.slice(6)
            })
          } else {
            let ref
            const slicedValue = newValue.slice(1)
            if (newValue.startsWith("#panel_issue_form") || newValue.startsWith("#panel_report_intake_form")) {
              ref = this.$refs[slicedValue]?.[0]
            } else {
              ref = this.$refs[slicedValue]
            }
            if (ref) {
              this.$vuetify.goTo(ref, {
                duration: 500,
                offset  : 0,
                easing  : "easeInOutCubic"
              })
            }
          }
        }
      }
    },
    showAddIssueFormTemplateDialog: {
      handler: function(value) {
        if (!value) {
          this.issueFormTemplateToBeAdded = []
          this.selectedIssueFormTemplate  = null
        }
      }
    },
    isIssueFormInstanceAdded: {
      handler: function(value) {
        if (value) {
          this.showAddIssueFormTemplateDialog = false
        }
      }
    },
    isFormInstanceRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "1041",
            parameters: {
              name: this.formInstanceToBeRemoved.formTemplateName
            }
          })
          this.showRemoveFormInstanceDialog = false
          this.formInstanceToBeRemoved      = undefined
        }
      }
    },
    isRemovingFormInstance: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_FORM_INSTANCE.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FORM_INSTANCE.pActions[1].buttonProps.loading  = newValue
      }
    },
    isTransitionDone: {
      handler: function(newValue) {
        if (newValue && this.latestTask && this.addNewTask) {
          this.addNewTask = false
          this.$router.push({
            name  : "task",
            params: {
              issueId: this.latestTask.parentId,
              id     : this.latestTask.id
            }
          })
        }
      }
    }
  }
}